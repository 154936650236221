import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import DashboardContainer from "./DashboardContainer";
import DashboardContainerHeader from "./DashboardContainerHeader";
import ListItem from "./ListItem";
import { Input, Row } from "reactstrap";
import RichTextEditor from "./Richtext";
import Notification from "./Notification";
import ChoicesSelect from "./ChoicesSelect";
import Kbutton from "c/KButton";
import { T, TraksConsumer } from "../../traks";
import { DataContext } from '../../DataContext';
import { APIDelete, APIGet, APIPost } from "../../API";
import { /* Dollars */ RenderIf, ToggleSwitch, VerifyDelete, AlertModal } from "c/Reusables";
import Map from "../Map";
import LDropDown from "c/LDropdown";
import DropLoad from './JacobsImageCrop';
import CreateButton from '../CreateButton';
import Sidebar from './Sidebar';
import { LL } from 'Routes';
import Accordion from 'c/Accordion';

export const DashboardWrapper = (props) => {
    return (
        <div className="dashboard mb-5">
            <CreateButton />
            <Sidebar index={props.index} />
            <div className="dashboard-wrapper">
                {props.children}
            </div>
        </div>
    )
}

export const EditVenueText = () => {

    const { data, setData } = useContext(DataContext);

    const thumb = <img className="mt-1" alt="thumb" width={22} height={18} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_text.svg"} />;

    return (
        <DashboardContainer>
            <DashboardContainerHeader thumb={thumb} border><T>Tekst</T></DashboardContainerHeader>
            <div className="px-4 pb-4 pt-4">
                <div>
                    <div className="mb-2">
                        <span><T>Beskrivelse</T></span>
                    </div>
                    <RichTextEditor fieldValue={data.description} update={(e) => setData({ ...data, description: e })} height={200} />
                </div>
                <div className="d-flex">
                    <div className="mt-3 w-100">
                        <div className="mb-2">
                            <span><T>Telefon</T></span>
                        </div>
                        <Input
                            value={data.phoneNumber}
                            onChange={(e) => setData({ ...data, phoneNumber: e.target.value.replace(/[^0-9+\s]/g, '') })}
                            type="tel"
                            className="py-3 text-weight-reg input-light-blue"
                        />
                    </div>
                    <div className="mt-3 w-100 ms-3">
                        <div className="mb-2">
                            <span><T>Email</T></span>
                        </div>
                        <Input
                            type="email"
                            value={data.email}
                            onChange={(e) => setData({ ...data, email: e.target.value })}
                            className="py-3 text-weight-reg input-light-blue"
                        />
                    </div>
                    <div className="mt-3 w-100 ms-3">
                        <div className="mb-2">
                            <span><T>Website URL</T></span>
                        </div>
                        <Input
                            value={data.website}
                            onChange={(e) => setData({ ...data, website: e.target.value })}
                            className="py-3 text-weight-reg input-light-blue"
                        />
                    </div>
                </div>
            </div>
        </DashboardContainer>
    );
}

export const CreatePartnerButton = (props) => {
    const user = props.user;

    return (
        <div className="mt-3 pe-2 w-100">
            <div>
                {props.createUserState &&
                    <div>
                        {user["screenName"] && user["firstName"] && user["lastName"] && user["phoneNumber"] && user["email"] && user["password"] ?
                            <Kbutton onClick={() => props.createUser()} custom="px-5 py-2" prim md><T>Create user</T></Kbutton>
                            :
                            <Kbutton md>Fill all values to create a user</Kbutton>}
                    </div>
                }
            </div>
        </div>
    );
}

export const PartnerInformation = ({ user, setUser, saveUser = null, isAdmin, createUserState, userStatus = null, deleteUser = null }) => {
    return (
        <div className="pe-2 w-100">
            <DashboardContainer>
                <DashboardContainerHeader info border><T>Partner information</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4">
                    <div className="w-100">
                        <div className="mb-2">
                            <span><T>Screen name</T></span>
                        </div>
                        <Input
                            className="py-3 text-weight-reg input-light-blue"
                            value={user.screenName}
                            onChange={(e) => setUser({ ...user, screenName: e.target.value })}
                        />
                    </div>
                    <div className="w-100 mt-4">
                        <div className="mb-2">
                            <span><T>Navn</T></span>
                        </div>
                        <Input
                            className="py-3 text-weight-reg input-light-blue"
                            value={user.firstName}
                            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                        />
                    </div>
                    <div className="w-100 mt-4">
                        <div className="mb-2">
                            <span><T>Efternavn</T></span>
                        </div>
                        <Input
                            className="py-3 text-weight-reg input-light-blue"
                            value={user.lastName}
                            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                        />
                    </div>
                    <div className="w-100 mt-4">
                        <div className="mb-2">
                            <span><T>Email</T></span>
                        </div>
                        <Input
                            type="email"
                            className="py-3 text-weight-reg input-light-blue"
                            value={user.email}
                            onChange={(e) => setUser({ ...user, email: e.target.value })}
                        />
                    </div>
                    <div className="w-100 mt-4">
                        <div className="mb-2">
                            <span><T>Phone</T></span>
                        </div>
                        <Input
                            type="tel"
                            className="py-3 text-weight-reg input-light-blue"
                            value={user.phoneNumber}
                            onChange={(e) => setUser({ ...user, phoneNumber: e.target.value.replace(/[^0-9+\s]/g, '') })}
                        />
                    </div>
                    <RenderIf condition={isAdmin && createUserState}>
                        <div>
                            <div className="mt-4 mb-3">
                                <span><T>Admin</T></span>
                            </div>
                            <div>
                                <input
                                    className="pb-3"
                                    readOnly
                                    type="checkbox"
                                    id={"handicapcheckbox"}
                                    checked={user.roles.length === 3}
                                    onClick={() => user.roles.length < 3 ? setUser({ ...user, roles: ["admin", "venueEditor", "companyAdmin"] }) : setUser({ ...user, roles: ["venueEditor", "companyAdmin"] })}
                                />
                            </div>
                        </div>
                    </RenderIf>
                    <RenderIf condition={!createUserState}>
                        <Kbutton
                            onClick={() => saveUser()}
                            custom="px-5 py-2 mt-3"
                            prim md
                        >
                            <T>Gem</T>
                        </Kbutton>
                    </RenderIf>
                </div>
            </DashboardContainer>
            <RenderIf condition={!createUserState && userStatus && isAdmin}>
                < DashboardContainer >
                    <DashboardContainerHeader admin border><T>Admin</T></DashboardContainerHeader>
                    <div className="pb-4 px-4">
                        <div className="mt-3 cursor_pointer" onClick={() => userStatus()} >
                            <Kbutton onClick={() => userStatus()} custom="px-5 py-2 mt-3" md><T>Change status</T></Kbutton>
                            <div className="ms-5">
                                <span className="py-5"><T>Status</T>:&nbsp;</span>
                                {user["active"] && user["active"] === true
                                    ? <span className="green_text"><T>Active</T></span>
                                    : <span className="red_text"><T>Inactive</T></span>
                                }
                            </div>
                        </div>
                        <div>
                            <div className="d-flex align-items-center mt-3">
                                <VerifyDelete
                                    deleteAction={() => deleteUser()}
                                    deleteText={<T>Delete user</T>}
                                    prompt={<T>Type 'delete user' to delete the user</T>}
                                    verifyText="delete user"
                                />
                            </div>
                        </div>
                    </div>
                </DashboardContainer>
            </RenderIf>
        </div >
    );
}

export const SetPassword = ({ data, createUserState, updateUser, savePassword = null }) => {

    const [canSave, setCanSave] = useState(false);
    const [noticePrompt, setNoticePrompt] = useState(false);
    const [user] = useState({
        'password': data !== undefined && data.password ? data.password : '',
    });

    const [newPassword, setNewPassword] = useState({
        'password': '',
        'passwordVerify': '',
    });

    useEffect(() => {
        let passwordRegex = /^(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (newPassword.password === newPassword.passwordVerify && passwordRegex.test(newPassword.password)) {
            setCanSave(true);
            createUserState && updateUser({ ...user, password: newPassword.password });
            setNoticePrompt(false);
        }
        else {
            setCanSave(false);
            createUserState && updateUser({ ...user, password: '' });
            newPassword.passwordVerify.length > 3 && setNoticePrompt(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPassword]);

    return (
        <DashboardContainer>
            <DashboardContainerHeader border><T>Skift adgangskode</T></DashboardContainerHeader>
            <div className="px-4 pb-4 pt-4">
                <Notification>
                    <T>Din adgangskode skal indeholde mindst 8 cifre, 1 stort og 1 specialtegn</T>
                </Notification>
                <div className="w-100 mt-4">
                    <div className="mb-2">
                        <span><T>Ny agangskode</T></span>
                    </div>
                    <Input
                        type="password"
                        className="py-3 text-weight-reg input-light-blue"
                        value={newPassword.password}
                        onChange={(e) => setNewPassword({ ...newPassword, password: e.target.value })}
                    />
                </div>
                <div className="w-100 mt-4">
                    <div className="mb-2">
                        <span><T>Bekræft ny adgangskode</T></span>
                    </div>
                    <Input
                        type="password"
                        className="py-3 text-weight-reg input-light-blue"
                        value={newPassword.passwordVerify}
                        onChange={(e) => setNewPassword({ ...newPassword, passwordVerify: e.target.value })}
                    />
                </div>
                {noticePrompt && <div className='my-2 red_text'><T>Din adgangskode skal indeholde mindst 8 cifre, 1 stort og 1 specialtegn</T></div>}
                <RenderIf condition={!createUserState}>
                    <Kbutton
                        disabled={!canSave || createUserState}
                        onClick={() => savePassword(newPassword.password)}
                        custom="px-5 py-2 mt-3"
                        prim md
                    >
                        <T>Gem</T>
                    </Kbutton>
                </RenderIf>
            </div>
        </DashboardContainer>
    );
}

export const SendPasswordReset = (props) => {

    return (
        <div>
            <DashboardContainer>
                <DashboardContainerHeader border><T>Skift adgangskode</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4">
                    <div className="w-100">
                        <div className="mb-2">
                            <span><T>Reset denne brugers adgangskode</T></span>
                        </div>
                        <Kbutton onClick={() => props.resetPassword()} custom="px-5 py-2 mt-3" prim md><T>Gem</T></Kbutton>
                    </div>
                </div>
            </DashboardContainer>
        </div>
    );
}

export const VenueIsVisible = ({ saveData, supplier = undefined }) => {

    const { data, setData } = useContext(DataContext);
    const [warningPrompt, setWarningPrompt] = useState(false);

    const warningtext = supplier ? <T>Add minimum 3 images, description, category, longitude and latitude before supplier can be made visible</T> : <T>Add minimum 5 images, description, capacities, longitude and latitude before venue can be made visible</T>

    const toggleVisibility = () => {
        if(!supplier) {
            if (!data.visibleOnWebsite) {
                if (data.categories !== undefined && data.categories.length && data.description !== undefined && data.description.length && data.geoLatLong !== undefined && data.geoLatLong.lon && data.geoLatLong.lat && data.imagesUrls !== undefined && data.imagesUrls.length > 4) {
                    setWarningPrompt(false);
                    setData({ ...data, visibleOnWebsite: !data.visibleOnWebsite });
                    saveData({ ...data, visibleOnWebsite: !data.visibleOnWebsite }, true);

                }
                else {
                    setWarningPrompt(true);
                }
            } else {
                setWarningPrompt(false);
                setData({ ...data, visibleOnWebsite: !data.visibleOnWebsite });
                saveData({ ...data, visibleOnWebsite: !data.visibleOnWebsite }, true);
            }
        } else {
            if (!data.visibleOnWebsite) {
                if (data.suppliercategories !== undefined && data.suppliercategories.length && data.description !== undefined && data.description.length && data.geoLatLong !== undefined && data.geoLatLong.lon && data.geoLatLong.lat && data.imagesUrls !== undefined && data.imagesUrls.length > 2) {
                    setWarningPrompt(false);
                    setData({ ...data, visibleOnWebsite: !data.visibleOnWebsite });
                    saveData({ ...data, visibleOnWebsite: !data.visibleOnWebsite }, true);

                }
                else {
                    setWarningPrompt(true);
                }
            } else {
                setWarningPrompt(false);
                setData({ ...data, visibleOnWebsite: !data.visibleOnWebsite });
                saveData({ ...data, visibleOnWebsite: !data.visibleOnWebsite }, true);
            }
        }
    };

    return (
        <div className="px-2 py-4">
            <span>
                {supplier ? <T>Supplier synligt på hjemmesiden</T> : <T>Mødested synligt på hjemmesiden</T>}
                <ToggleSwitch
                    isOn={data.visibleOnWebsite}
                    handleToggle={() => toggleVisibility()}
                />
            </span>
            {warningPrompt && <div className="mt-1 red_text">{warningtext}</div>}
        </div>
    );
}

export const Gallery = ({ saveData, uploadImages, uploadLogo = undefined, supplier = undefined, landingPage = undefined }) => {

    const { data, setData } = useContext(DataContext);
    const [files, setFiles] = useState([]);
    const [filesLogo, setFilesLogo] = useState([]);

    const deleteLogo = () => {
        saveData({ ...data, iconUrl: '' });
        setFilesLogo([])
    }
    const deleteImage = (e) => {
        saveData({ ...data, imagesUrls: e });
        setFiles([])
    }
    const handleUploadImage = (files) => {
        setFiles([])
        uploadImages(files)
    }
    const handleUploadLogo = (files) => {
        setFilesLogo([])
        uploadLogo(files)
    }

    const infoText =
    <div>
        <span className="text-sm"><T>Ved at vælge (klikke på et foto) et af de uploadede fotos, indstiller du det som Udvalgt billede (markeret med ikon med stjerne). Træk og slip billederne for at ændre rækkefølgen.</T></span>
        <span><br /><T>Billeder skal minimum være 1200px i bredden</T></span>
    </div>;

    const thumb = <img className='mt-1' alt="gallerythumb" width={24} height={21} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_gallery.svg"} />

    return (
        <DashboardContainer>
            <DashboardContainerHeader thumb={thumb} border><T>Galleri</T></DashboardContainerHeader>
            <div className="px-4 pb-4 pt-4">
                <Notification>{infoText}</Notification>
                <div className="d-flex">
                    <div className="w-100">
                        <div className="mb-2">
                            <span><T>Galleri</T></span>
                        </div>
                        <DropLoad
                            supplier={supplier}
                            files={files}
                            setFiles={setFiles}
                            setDeleted={deleteImage}
                            uploadFiles={handleUploadImage}
                            updateOrder={(e) => setData({ ...data, imagesUrls: e })}
                            multiple={true}
                            imagesUrls={data.imagesUrls}
                            canUpload={!filesLogo.length}
                            minWidth={1200}
                        />
                    </div>
                    {/* (!supplier || !landingPage) &&
                        <div style={{ width: 300 }} className="ms-3">
                            <div className="mb-2">
                                <span><T>Logo</T></span>
                            </div>
                            <DropLoad
                                supplier={supplier}
                                files={filesLogo}
                                setFiles={setFilesLogo}
                                setDeleted={deleteLogo}
                                uploadFiles={handleUploadLogo}
                                multiple={false}
                                imagesUrls={data.iconUrl}
                                canUpload={!files.length}
                            />
                        </div>
                    */}
                </div>
            </div>
        </DashboardContainer>
    );
}

export const EditVenueInfo = (props) => {

    const { data, setData, certificates, facilities, locations, categories } = useContext(DataContext);

    const setProduct = (e, attribute) => {
        if (data["products"]) {
            const foundProduct = data.products.find(products => products.name === attribute)
            if (foundProduct) {
                let newProducts = data.products.map(p => {
                    const newP = p
                    if (p.name === attribute) {
                        newP.price = e
                    }
                    return newP
                })
                setData({ ...data, products: newProducts })
            } else {
                let newProducts = data["products"]
                newProducts.push({ name: attribute, price: e })
            }
        } else {
            setData({ ...data, products: [{ name: attribute, price: e }] });
        }
    };

    const [inputDayPrice, setInputDayPrice] = useState('');
    const [inputKursus, setInputKursus] = useState('');

    useEffect(() => {
        if (data.products !== undefined && data.products.length) {
            if (data.products.some(obj => obj.name === 'meetingOneDay')) {
                const dayPrice = data.products.find(obj => obj.name === 'meetingOneDay').price;
                setInputDayPrice(dayPrice);
            }
            if (data.products.some(obj => obj.name === 'courseTwentyFourHours')) {
                const coursePrice = data.products.find(obj => obj.name === 'courseTwentyFourHours').price;
                setInputKursus(coursePrice);
            }
        }
        else {
            setData(prevState => { return { ...prevState, products: [] } });
        }
        // eslint-disable-next-line
    }, []);

    const thumb = <img alt="thumb" width={22} height={22} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_modested.svg"} />

    return (
        <div>
            <DashboardContainer>
                <DashboardContainerHeader thumb={thumb} border><T>Mødested</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4">
                    <div className="d-flex">
                        <div className="w-100 me-3" style={{ flex: 5 }}>
                            <div className="mb-2">
                                <span><T>Titel</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={data.title}
                                onChange={(e) => setData({ ...data, title: e.target.value })}
                            />
                        </div>
                        <div className="w-100 me-3" style={{ flex: 1 }}>
                            <div className="mb-2">
                                <span><T>Dagsmøde pris</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={inputDayPrice ? inputDayPrice : ''}
                                onChange={(e) => {
                                    setInputDayPrice(e.target.value.replace(/\D/g, ''));
                                    setProduct(e.target.value.replace(/\D/g, ''), "meetingOneDay");
                                }}
                            />
                        </div>
                        <div className="w-100" style={{ flex: 1 }}>
                            <div className="mb-2">
                                <span><T>Kursusdøgn pris</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={inputKursus ? inputKursus : ''}
                                onChange={(e) => {
                                    setInputKursus(e.target.value.replace(/\D/g, ''));
                                    setProduct(e.target.value.replace(/\D/g, ''), "courseTwentyFourHours");
                                }}
                            />
                        </div>
                    </div>
                    {props.isAdmin && <div className="w-100 mt-4">
                        <div className="mb-2">
                            <span><T>Kategori</T></span>
                        </div>
                        <ChoicesSelect sendSelected={(e) => setData({ ...data, categories: e })} data={categories} venueData={data.categories} subtitle={<T>Vælg én eller flere kategorier</T>} />
                    </div>}
                    <div className="w-100 mt-4">
                        <div className="mb-2">
                            <span><T>Faciliteter</T></span>
                        </div>
                        <ChoicesSelect sendSelected={(e) => setData({ ...data, facilities: e })} data={facilities} venueData={data.facilities} subtitle={<T>Vælg én eller flere faciliteter</T>} searchPlaceholder={props.render_static(<T>Søg på faciliteter</T>)} showSearchBar />
                    </div>
                    <div className="w-100 mt-4">
                        <div className="mb-2">
                            <span><T>Miljømærker</T></span>
                        </div>
                        <ChoicesSelect sendSelected={(e) => setData({ ...data, certificates: e })} data={certificates} venueData={data.certificates} subtitle={<T>Tilknyt Miljømærker</T>} />
                    </div>
                    {props.isAdmin && <div className="w-100 mt-4">
                        <div className="mb-2">
                            <span><T>Regioner</T></span>
                        </div>
                        <ChoicesSelect sendSelected={(e) => setData({ ...data, locations: e })} data={locations} venueData={data.locations} subtitle={<T>Tilknyt Regioner</T>} searchPlaceholder={props.render_static(<T>Søg på lokationer</T>)} showSearchBar />
                    </div>}
                </div>
            </DashboardContainer>
        </div>
    );
}

export const EditSupplierInfo = (props) => {

    const { data, setData, locations, categories } = useContext(DataContext);

    const setProduct = (e, attribute) => {
        if (data["products"]) {
            const foundProduct = data.products.find(products => products.name === attribute)
            if (foundProduct) {
                let newProducts = data.products.map(p => {
                    const newP = p
                    if (p.name === attribute) {
                        newP.price = e
                    }
                    return newP
                })
                setData({ ...data, products: newProducts })
            } else {
                let newProducts = data["products"]
                newProducts.push({ name: attribute, price: e })
            }
        } else {
            setData({ ...data, products: [{ name: attribute, price: e }] });
        }
    };

    const [inputDayPrice, setInputDayPrice] = useState('');
    const [inputKursus, setInputKursus] = useState('');

    useEffect(() => {
        if (data.products !== undefined && data.products.length) {
            if (data.products.some(obj => obj.name === 'meetingOneDay')) {
                const dayPrice = data.products.find(obj => obj.name === 'meetingOneDay').price;
                setInputDayPrice(dayPrice);
            }
            if (data.products.some(obj => obj.name === 'courseTwentyFourHours')) {
                const coursePrice = data.products.find(obj => obj.name === 'courseTwentyFourHours').price;
                setInputKursus(coursePrice);
            }
        }
        else {
            setData(prevState => { return { ...prevState, products: [] } });
        }
        // eslint-disable-next-line
    }, []);

    const thumb = <img alt="thumb" width={22} height={22} src={"https://static.optimeet.dk/gfx/ico_small_user.svg"} />

    return (
        <div>
            <DashboardContainer>
                <DashboardContainerHeader thumb={thumb} border><T>Supplier</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4">
                    <div className="d-flex">
                        <div className="w-100 me-3" style={{ flex: 5 }}>
                            <div className="mb-2">
                                <span><T>Titel</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={data.title}
                                onChange={(e) => setData({ ...data, title: e.target.value })}
                            />
                        </div>
                        <div className="w-100 me-3" style={{ flex: 1 }}>
                            <div className="mb-2">
                                <span><T>Dagsmøde pris</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={inputDayPrice ? inputDayPrice : ''}
                                onChange={(e) => {
                                    setInputDayPrice(e.target.value.replace(/\D/g, ''));
                                    setProduct(e.target.value.replace(/\D/g, ''), "meetingOneDay");
                                }}
                            />
                        </div>
                        <div className="w-100" style={{ flex: 1 }}>
                            <div className="mb-2">
                                <span><T>Kursusdøgn pris</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={inputKursus ? inputKursus : ''}
                                onChange={(e) => {
                                    setInputKursus(e.target.value.replace(/\D/g, ''));
                                    setProduct(e.target.value.replace(/\D/g, ''), "courseTwentyFourHours");
                                }}
                            />
                        </div>
{/*                         <div className="w-100" style={{ flex: 1 }}>
                            <div className="mb-2">
                                <span><T>Kursusdøgn pris</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={inputKursus ? inputKursus : ''}
                                onChange={(e) => {
                                    setInputKursus(e.target.value.replace(/\D/g, ''));
                                    setProduct(e.target.value.replace(/\D/g, ''), "courseTwentyFourHours");
                                }}
                            />
                        </div> */}
                    </div>
                    {props.isAdmin &&
                        <div className="w-100 mt-4">
                            <div className="mb-2">
                                <span><T>Kategori</T></span>
                            </div>
                            <ChoicesSelect sendSelected={(e) => setData({ ...data, suppliercategories: e })} data={categories} venueData={data.suppliercategories} subtitle={<T>Vælg én eller flere kategorier</T>} />
                            <div className="w-100 mt-4">
                                <div className="mb-2">
                                    <span><T>Regioner</T></span>
                                </div>
                                <ChoicesSelect sendSelected={(e) => setData({ ...data, locations: e })} data={locations} venueData={data.locations} subtitle={<T>Tilknyt Regioner</T>} searchPlaceholder={props.render_static(<T>Søg på lokationer</T>)} showSearchBar />
                            </div>
                        </div>
                    }
                </div>
            </DashboardContainer>
        </div>
    );
}

export const EditVenueLocation = () => {

    const { data, setData } = useContext(DataContext);

    const handleLatLonChange = (e, geo) => {
        let value = e.target.value;
        if (value.charAt(0) === '.') {
            value = value.slice(1);
        }
        if (value.charAt(value.length - 1) === '.') {
            value = value.slice(0, -1);
        }
        const newVenue = { ...data };
        newVenue.geoLatLong = newVenue.geoLatLong ?? {};
        newVenue.geoLatLong[geo] = value.replace(/[^0-9.-]/g, '');
        setData(newVenue);
    };

    const thumb = <img className='mt-1' alt="thumb" width={18} height={24} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_geo.svg"} />;

    return (
        <div>
            <DashboardContainer>
                <DashboardContainerHeader thumb={thumb} border><T>Lokation</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4 d-flex">
                    <div className="w-100 me-3">
                        <div className="w-100">
                            <div className="mb-2">
                                <span><T>Adresse</T></span>
                            </div>
                            <Input
                                value={data.address_1}
                                onChange={(e) => setData({ ...data, address_1: e.target.value })}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="w-100">
                            <div className="my-3">
                                <span><T>Region</T></span>
                            </div>
                            <Input
                                value={data.address_2}
                                onChange={(e) => setData({ ...data, address_2: e.target.value })}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="w-100">
                            <div className="my-3">
                                <span><T>City</T></span>
                            </div>
                            <Input
                                value={data.city}
                                onChange={(e) => setData({ ...data, city: e.target.value })}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="w-100">
                            <div className="my-3">
                                <span><T>Zip</T></span>
                            </div>
                            <Input
                                value={data.zipcode}
                                onChange={(e) => setData({ ...data, zipcode: e.target.value.replace(/\D/g, '') })}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="w-100 mt-4">
                            <div className="mb-2">
                                <span><T>Google map adresse</T></span>
                            </div>
                            <Input
                                value={data.googleMapsAddress}
                                onChange={(e) => setData({ ...data, googleMapsAddress: e.target.value })}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                    </div>
                    <div className="w-100">
                        <div className="w-100">
                            <div className="mb-2">
                                <span><T>Længdegrad</T></span>
                            </div>
                            <Input
                                value={data.geoLatLong && data.geoLatLong.lat ? data.geoLatLong.lat : ''}
                                onChange={(e) => handleLatLonChange(e, "lat")}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="w-100 mt-4">
                            <div className="mb-2">
                                <span><T>Breddegrad</T></span>
                            </div>
                            <Input
                                value={data.geoLatLong && data.geoLatLong.lon ? data.geoLatLong.lon : ''}
                                onChange={(e) => handleLatLonChange(e, "lon")}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        {data.geoLatLong !== undefined && data.geoLatLong.lat !== undefined && data.geoLatLong.lon !== undefined &&
                            <div>
                                {(!data.geoLatLong.lat.includes('.') || !data.geoLatLong.lon.includes('.') || (data.geoLatLong.lat.match(/\./g) || []).length > 1 || (data.geoLatLong.lon.match(/\./g) || []).length > 1) && <span className='red_text'>Longitude & Latitude must have one dot (.)</span>}
                                <div className='my-3'>
                                    <Map data={data} customCss={"previewmap"} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </DashboardContainer>
        </div>
    );
}


export const EditVenueInformation = () => {

    const { data, setData } = useContext(DataContext);

    const setCapacity = (e, attribute) => {
        if (data["capacities"]) {
            const foundCapacity = data.capacities.some(capacities => capacities.name === attribute)
            if (foundCapacity) {
                let newCapacities = data.capacities.map(c => {
                    const newC = c
                    if (c.name === attribute) {
                        newC.value = Number(e)
                    }
                    return newC
                })
                setData({ ...data, capacities: newCapacities })
            } else {
                let newCapacities = data["capacities"]
                newCapacities.push({ name: attribute, value: Number(e) });
            }
        } else {
            setData({ ...data, capacities: [{ name: attribute, value: Number(e) }] })
        }
    };

    const [inputParticipant, setInputParticipant] = useState('');
    const [inputMeetingLocations, setInputMeetingLocations] = useState('');
    const [inputEatingPlaces, setInputEatingPlaces] = useState('');
    const [inputRooms, setInputRooms] = useState('');
    const [inputGroupRooms, setInputGroupRooms] = useState('');
    const [inputAuditoriums, setInputAuditoriums] = useState('');
    const [inputCapacity, setInputCapacity] = useState('');
    const [inputMaxPeopleAuditorium, setInputMaxPeopleAuditorium] = useState('');
    const [inputMaxPeopleSchoolTable, setInputMaxPeopleSchoolTable] = useState('');
    const [inputMaxPeopleUTable, setInputMaxPeopleUTable] = useState('');
    const [inputSetup, setInputSetup] = useState('');
    const [inputReception, setInputReception] = useState('');

    useEffect(() => {
        if (data.capacities !== undefined && data.capacities.length) {
            if (data.capacities.some(obj => obj.name === 'participants')) {
                const participantsCapacity = data.capacities.find(obj => obj.name === 'participants').value;
                setInputParticipant(participantsCapacity);
            }
            if (data.capacities.some(obj => obj.name === 'meetingLocations')) {
                const participantsMeetingLoc = data.capacities.find(obj => obj.name === 'meetingLocations').value;
                setInputMeetingLocations(participantsMeetingLoc);
            }
            if (data.capacities.some(obj => obj.name === 'eatingPlaces')) {
                const participantsEatingplaces = data.capacities.find(obj => obj.name === 'eatingPlaces').value;
                setInputEatingPlaces(participantsEatingplaces);
            }
            if (data.capacities.some(obj => obj.name === 'rooms')) {
                const participantRooms = data.capacities.find(obj => obj.name === 'rooms').value;
                setInputRooms(participantRooms);
            }
            if (data.capacities.some(obj => obj.name === 'groupRooms')) {
                const groupRooms = data.capacities.find(obj => obj.name === 'groupRooms').value;
                setInputGroupRooms(groupRooms);
            }
            if (data.capacities.some(obj => obj.name === 'auditoriums')) {
                const groupAud = data.capacities.find(obj => obj.name === 'auditoriums').value;
                setInputAuditoriums(groupAud);
            }
            if (data.capacities.some(obj => obj.name === 'capacity')) {
                const totalCapacity = data.capacities.find(obj => obj.name === 'capacity').value;
                setInputCapacity(totalCapacity);
            }
            if (data.capacities.some(obj => obj.name === 'max_people_auditoriums')) {
                const auditoriumCapacity = data.capacities.find(obj => obj.name === 'max_people_auditoriums').value;
                setInputMaxPeopleAuditorium(auditoriumCapacity);
            }
            if (data.capacities.some(obj => obj.name === 'schoolTables')) {
                const schoolTablesCapacity = data.capacities.find(obj => obj.name === 'schoolTables').value;
                setInputMaxPeopleSchoolTable(schoolTablesCapacity);
            }
            if (data.capacities.some(obj => obj.name === 'u-tables')) {
                const uTablesCapacity = data.capacities.find(obj => obj.name === 'u-tables').value;
                setInputMaxPeopleUTable(uTablesCapacity);
            }
            if (data.capacities.some(obj => obj.name === 'setup')) {
                const setup = data.capacities.find(obj => obj.name === 'setup').value;
                setInputSetup(setup);
            }
            if (data.capacities.some(obj => obj.name === 'reception')) {
                const reception = data.capacities.find(obj => obj.name === 'reception').value;
                setInputReception(reception);
            }
        }
        else {
            setData(prevState => { return { ...prevState, capacities: [] } });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <DashboardContainer>
            <DashboardContainerHeader info border><T>Information</T></DashboardContainerHeader>
            <div className="px-4 pb-4 pt-4">
                <div>
                    <div className="d-flex">
                        <div className="mt-3 w-100">
                            <div className="mb-2">
                                <span><T>Deltagere</T></span>
                            </div>
                            <Input
                                value={inputParticipant ? inputParticipant : ''}
                                onChange={(e) => {
                                    setInputParticipant(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "participants");
                                }}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Mødelokaler</T></span>
                            </div>
                            <Input
                                value={inputMeetingLocations ? inputMeetingLocations : ''}
                                onChange={(e) => {
                                    setInputMeetingLocations(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "meetingLocations");
                                }}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Spisepladser</T></span>
                            </div>
                            <Input className="py-3 text-weight-reg input-light-blue"
                                value={inputEatingPlaces ? inputEatingPlaces : ''}
                                onChange={(e) => {
                                    setInputEatingPlaces(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "eatingPlaces");
                                }}
                            />
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Værelser</T></span>
                            </div>
                            <Input className="py-3 text-weight-reg input-light-blue"
                                value={inputRooms ? inputRooms : ''}
                                onChange={(e) => {
                                    setInputRooms(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "rooms");
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="d-flex pt-3">
                        <div className="mt-3 w-100">
                            <div className="mb-2">
                                <span><T>Gruppelokaler</T></span>
                            </div>
                            <Input className="py-3 text-weight-reg input-light-blue"
                                value={inputGroupRooms ? inputGroupRooms : ''}
                                onChange={(e) => {
                                    setInputGroupRooms(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "groupRooms");
                                }}
                            />
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Auditorier</T></span>
                            </div>
                            <Input className="py-3 text-weight-reg input-light-blue"
                                value={inputAuditoriums ? inputAuditoriums : ''}
                                onChange={(e) => {
                                    setInputAuditoriums(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "auditoriums");
                                }}
                            />
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Statsaftale</T></span>
                            </div>
                            <div className="py-1 px-0">
                                <LDropDown
                                    data={statsaftaleData}
                                    placeholder={data.governmentDeal ? data.governmentDeal : "-"}
                                    height={50}
                                    setVal={(clickData) => {
                                        setData({ ...data, governmentDeal: clickData });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Handicapvenlig</T></span>
                            </div>
                            <input
                                className='pt-3'
                                readOnly
                                type="checkbox"
                                id={"handicapcheckbox"}
                                checked={data.handicapFriendly}
                                onClick={() => setData({ ...data, handicapFriendly: !data.handicapFriendly })}
                            />
                        </div>
                    </div>
                    <div className="py-4 border-1 border-bottom"><T>Kapacitet</T></div>
                    <div className="d-flex">
                        <div className="mt-3 w-100">
                            <div className="mb-2">
                                <span><T>Max capacitet</T></span>
                            </div>
                            <Input
                                value={inputCapacity ? inputCapacity : ''}
                                onChange={(e) => {
                                    setInputCapacity(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "capacity");
                                }}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Max pers. i auditorium</T></span>
                            </div>
                            <Input
                                value={inputMaxPeopleAuditorium ? inputMaxPeopleAuditorium : ''}
                                onChange={(e) => {
                                    setInputMaxPeopleAuditorium(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "max_people_auditoriums");
                                }}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Max pers. ved skoleborde</T></span>
                            </div>
                            <Input className="py-3 text-weight-reg input-light-blue"
                                value={inputMaxPeopleSchoolTable ? inputMaxPeopleSchoolTable : ''}
                                onChange={(e) => {
                                    setInputMaxPeopleSchoolTable(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "schoolTables");
                                }}
                            />
                        </div>
                        <div className="mt-3 w-100 ms-3">
                            <div className="mb-2">
                                <span><T>Max pers. i U-bord</T></span>
                            </div>
                            <Input className="py-3 text-weight-reg input-light-blue"
                                value={inputMaxPeopleUTable ? inputMaxPeopleUTable : ''}
                                onChange={(e) => {
                                    setInputMaxPeopleUTable(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "u-tables");
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="mt-3 pe-3 w-25">
                            <div className="mb-2">
                                <span><T>Max pers. i ø-opstilling á 6-8 personer</T></span>
                            </div>
                            <Input
                                value={inputSetup ? inputSetup : ''}
                                onChange={(e) => {
                                    setInputSetup(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "setup");
                                }}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                        <div className="mt-3 w-25">
                            <div className="mb-2">
                                <span><T>Max pers. ved stående reception</T></span>
                            </div>
                            <Input
                                value={inputReception ? inputReception : ''}
                                onChange={(e) => {
                                    setInputReception(e.target.value.replace(/\D/g, ''));
                                    setCapacity(e.target.value.replace(/\D/g, ''), "reception");
                                }}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                    </div>
                    {/*} Hide until they want it - (it works)
					<div className="d-flex">
						<div className="mt-3 w-100">
							<div className="mb-2">
								<span><T>Pris niveau</T></span>
							</div>
							<Dollars editVenue count={data.priceRating} setValue={(e) => setData({ ...data, priceRating: e })} />
						</div>
					</div>
							{*/}
                    <div className="mt-4 w-100">
                        <div className="mb-2">
                            <span><T>Kapacitet beskrivelse</T></span>
                        </div>
                        <RichTextEditor fieldValue={data.capacityDescription} update={(e) => setData({ ...data, capacityDescription: e })} height={200} />
                    </div>
                </div>
            </div>
        </DashboardContainer>
    );
}

const statsaftaleData = [
    {
        name: "-",
        id: '',
    },
    {
        name: "Hotelophold",
        id: "Hotelophold"
    },
    {
        name: "Konferencer",
        id: "Konferencer"
    },
];

export const Settings = ({ supplier = undefined }) => {

    const { data } = useContext(DataContext);
    const navigate = useNavigate();

    const deleteVenue = () => {
        const resource = supplier ? "suppliers/" + data.id : "venues/" + data.id
        APIDelete(resource).then(res => {
            navigate(LL(supplier ? "/mysuppliers/" : "/myvenues/"));
        }).catch(err => {
            console.log(err)
        })
    };

    return (
        <DashboardContainer>
            <DashboardContainerHeader admin border><T>Indstillinger</T></DashboardContainerHeader>
            <div className="px-4 pb-4 pt-4">
                <div className="w-100 me-3 d-flex">
                    <div className="w-100">
                        <div className="mb-2 mt-1">
                            <span>{supplier ? <T>Slet supplier</T> : <T>Slet mødested</T>}</span>
                        </div>
                        <div>
                            <VerifyDelete
                                deleteAction={() => deleteVenue()}
                                deleteText={supplier ? <T>Delete supplier</T> : <T>Delete venue</T>}
                                prompt={supplier ? <T>Type 'delete supplier' to delete the supplier</T> : <T>Type 'delete venue' to delete the venue</T>}
                                verifyText={supplier ? "delete supplier" : "delete venue"} />
                        </div>
                    </div>
                </div>
            </div>
        </DashboardContainer>
    );
}

export const ShareVenuePartner = (props) => {

    const { Id, partners, setPartners, data, setData } = useContext(DataContext);
    const [allUsers] = useState(partners);

    const sendUsers = (e) => {
        const userIds = e.map(user => user.id);
        let body = { userIds: userIds };
        APIPost(props.supplier ? "suppliers/" + Id + "/assign_users" : "venues/" + Id + "/assign_users", body).then(res => {
            setData({ ...data, assigned_users: userIds });
            setPartners(userIds);
        }).catch(err => {
            console.log("update errored:", err);
        })
    };

    return (
        <DashboardContainer>
            <DashboardContainerHeader border><T>Partners</T></DashboardContainerHeader>
            {allUsers.length &&
                <div className="px-4 pb-4 pt-4">
                    <div className="w-100 me-3 d-flex">
                        <div className="w-100 me-3">
                            <div className="mb-2">
                                <span>{props.supplier ? <T>Tildel supplier til Partner</T> : <T>Tildel mødested til Partner</T>}</span>
                            </div>
                            <ChoicesSelect sendSelected={sendUsers} data={partners} venueData={data.assigned_users !== undefined && partners.filter(partner => data.assigned_users.includes(partner.id))} users subtitle={<T>Valg partner</T>} searchPlaceholder={props.render_static(<T>Søg på partnere</T>)} showSearchBar />
                        </div>
                    </div>
                </div>
            }
        </DashboardContainer>
    );
}

export const ListActivity = () => {

    /* 	const mockData = [
            {
                id: 1,
                venue: "Salen",
                dateUpdated: "7 måneder siden"
            },
            {
                id: 2,
                venue: "Salen",
                dateUpdated: "7 måneder siden"
            },
            {
                id: 3,
                venue: "Comwell Århus",
                dateUpdated: "7 måneder siden"
            },
            {
                id: 4,
                venue: "Comwell Århus",
                dateUpdated: "7 måneder siden"
            },
            {
                id: 5,
                venue: "Salen",
                dateUpdated: "7 måneder siden"
            },
        ]; */

    return (
        <DashboardContainer>
            <DashboardContainerHeader>
                <div>
                    <T>Aktiviteter</T>
                </div>
                <div>
                    <span className="nav-link">
                        <span className="k-btn k-btn-grey px-4 py-2">
                            <T>Ryd</T>
                        </span>
                    </span>
                </div>
            </DashboardContainerHeader>
            {/*}
			{mockData.map((item, i) => (
				<ListItem key={i} icon="venue" title={item.venue + " Salen blev opdateret"} remove badge={item.dateUpdated} />
			))}
			{*/}
            <div className="text-center pb-5 pt-5">
                <div className="mb-5">
                    Aktivitetslisten er under udvikling
                </div>
            </div>
        </DashboardContainer>
    );
}

export const ListCategories = ({ supplier = undefined }) => {

    const [data, setData] = useState([]);
    const [queriedData, setQueriedData] = useState(data);
    const getType = supplier ? "suppliercategories" : "categories"
    const url = supplier ? "/editsuppliercategory/" : "/editcategory/";

    useEffect(() => {
        APIGet(getType, {}).then(res => {
            !supplier && setData(res.data.categories);
            !supplier && setQueriedData(res.data.categories);
            supplier && setData(res.data.suppliercategories);
            supplier && setQueriedData(res.data.suppliercategories);
        }).catch(err => {
            setAlertType({ type: "error", text: err.response.data });
            setShowAlert(true);
        });
        // eslint-disable-next-line
    }, [])

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });
    const searchPlaceholder = <T>Search</T>;

    return (
        <DashboardContainer>
            <AlertModal
                isShowing={showAlert}
                setIsShowing={setShowAlert}
                type={alertType.type}
                text={alertType.text}
            />
            <TraksConsumer>
                <DashboardContainerHeader
                    valueToSearch={"name"}
                    data={data}
                    searchPlaceholder={searchPlaceholder}
                    setData={setQueriedData}
                    search
                >
                    {supplier ? <T>Supplier</T> : <T>Kategorier</T>}
                </DashboardContainerHeader>
            </TraksConsumer>
            {queriedData.map((item, i) => (
                <ListItem key={i} icon="category" title={item.name} edit url={LL(url + item.id)} />
            ))}
        </DashboardContainer>
    );
}

export const ListFacilities = () => {

    const [data, setData] = useState([]);
    const [queriedData, setQueriedData] = useState(data);

    useEffect(() => {
        APIGet("facilities", {}).then(res => {
            setData(res.data.facilities);
            setQueriedData(res.data.facilities);
        }).catch(err => {
            setAlertType({ type: "error", text: err.response.data });
            setShowAlert(true);
        });
    }, [])

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });
    const searchPlaceholder = <T>Search</T>;

    return (
        <DashboardContainer>
            <AlertModal
                isShowing={showAlert}
                setIsShowing={setShowAlert}
                type={alertType.type}
                text={alertType.text}
            />
            <TraksConsumer>
                <DashboardContainerHeader
                    valueToSearch={"name"}
                    data={data}
                    searchPlaceholder={searchPlaceholder}
                    setData={setQueriedData}
                    search
                >
                    <T>Faciliteter</T>
                </DashboardContainerHeader>
            </TraksConsumer>
            {queriedData.map((item, i) => (
                <ListItem key={i} icon="user" title={item.name} edit url={LL("/editfacility/" + item.id)} />
            ))}
        </DashboardContainer>
    );
}

export const ListCertificates = () => {

    const [data, setData] = useState([]);
    const [queriedData, setQueriedData] = useState(data);

    useEffect(() => {
        APIGet("certificates", {}).then(res => {
            setData(res.data.certificates);
            setQueriedData(res.data.certificates);
        }).catch(err => {
            setAlertType({ type: "error", text: err.response.data });
            setShowAlert(true);
        });
    }, [])

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });
    const searchPlaceholder = <T>Search</T>;

    return (
        <DashboardContainer>
            <AlertModal
                isShowing={showAlert}
                setIsShowing={setShowAlert}
                type={alertType.type}
                text={alertType.text}
            />
            <TraksConsumer>
                <DashboardContainerHeader
                    valueToSearch={"name"}
                    data={data}
                    searchPlaceholder={searchPlaceholder}
                    setData={setQueriedData}
                    search
                >
                    <T>Miljømærker</T>
                </DashboardContainerHeader>
            </TraksConsumer>
            {queriedData.map((item, i) => (
                <ListItem key={i} title={item.name} url={LL("/editcertificate/" + item.id)} icon={item.imageUrl === "" || item.imageUrl === undefined ? 'user' : item.imageUrl} edit />
            ))}
        </DashboardContainer>
    );
}

export const ListRegions = () => {

    const [data, setData] = useState([]);
    const [queriedData, setQueriedData] = useState(data);

    useEffect(() => {
        APIGet("locations", {}).then(res => {
            setData(res.data.locations);
            setQueriedData(res.data.locations);
        }).catch(err => {
            setAlertType({ type: "error", text: err.response.data });
            setShowAlert(true);
        });
    }, [])

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState({ type: "", text: "" });
    const searchPlaceholder = <T>Search</T>;

    return (
        <DashboardContainer>
            <AlertModal
                isShowing={showAlert}
                setIsShowing={setShowAlert}
                type={alertType.type}
                text={alertType.text}
            />
            <TraksConsumer>
                <DashboardContainerHeader
                    valueToSearch={"name"}
                    data={data}
                    searchPlaceholder={searchPlaceholder}
                    setData={setQueriedData}
                    search
                >
                    <T>Regioner</T>
                </DashboardContainerHeader>
            </TraksConsumer>
            {queriedData.map((item, i) => (
                <ListItem key={i} icon="user" title={item.name} edit url={LL("/editregion/" + item.id)} />
            ))}
        </DashboardContainer>
    );
}

export const EditGroup = ({ classname = undefined, uploadImage, values, setValues, save, deleteItem }) => {

    const [files, setFiles] = useState([])

    const deleteImage = () => {
        setFiles([]);
        setValues({ ...values, imageUrl: "" });
        save({ ...values, imageUrl: "" });
    }

    const handleUploadImage = (images) => {
        uploadImage(files);
        setFiles([]);
    }

    const images = (values["imageUrl"] && values["imageUrl"] !== "") ? values["imageUrl"] : "";

    return (
        <div className={classname}>
            <DashboardContainer>
                <DashboardContainerHeader info border><T>Information</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4">
                    <div className="w-100">
                        <div className="mb-2">
                            <span><T>Navn</T></span>
                        </div>
                        <Input
                            type="text"
                            className="py-3 text-weight-reg input-light-blue"
                            value={values.name}
                            onChange={(e) => setValues({ ...values, name: e.target.value, key: e.target.value.toLowerCase().replace(/\s/g, '-').replace(/-/g, '').replace(/ø/g, 'oe').replace(/æ/g, 'ae').replace(/å/g, 'o') })}
                        />
                    </div>
                    {values["name"] &&
                        <div className="w-100 mt-4">
                            <div className="mb-2">
                                <span><T>URL</T></span><img className='mb-1 ms-1' alt="thumb" width={10} height={16} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_lock.svg"} />
                            </div>
                            <Input
                                disabled
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={values.name.toLowerCase().replace(/-/g, '').replace(/\s/g, '-').replace(/ø/g, 'oe').replace(/æ/g, 'ae').replace(/å/g, 'o')}
                            />
                        </div>
                    }
                    {uploadImage !== undefined && (
                        <div className="my-4">
                            <div className="mb-2">
                                <span><T>Billede</T></span>
                            </div>
                            <DropLoad
                                files={files}
                                setFiles={setFiles}
                                setDeleted={() => deleteImage()}
                                uploadFiles={handleUploadImage}
                                updateOrder={null}
                                multiple={false}
                                imagesUrls={images}
                                canUpload={true}
                            />
                        </div>
                    )}
                </div>
            </DashboardContainer>
            <Kbutton onClick={() => save()} custom="px-5 py-2 mt-3" prim md><T>Gem</T></Kbutton>
            <div className='mt-3'>
                <VerifyDelete
                    deleteAction={() => deleteItem()}
                    deleteText={<T>Delete item</T>}
                    prompt={<T>Type 'delete item' to delete the item</T>}
                    verifyText="delete item" />
            </div>
        </div>
    );
}

export const EditNewsContent = ({ uploadImage, deleteNews, news, setNews, saveNews, ...props }) => {
    const [files, setFiles] = useState([])

    const deleteImage = () => {
        setFiles([])
        setNews({ ...news, imageUrl: "" })
        saveNews({ ...news, imageUrl: "" })
    }

    const handleUploadImage = (images) => {
        uploadImage(files)
        setFiles([])
    }

    const images = (news["imageUrl"] && news["imageUrl"] !== "") ? news["imageUrl"] : ""

    return (
        <div className="w-100">
            <DashboardContainer>
                <DashboardContainerHeader border><T>Tekst</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4">
                    <div className="d-flex">
                        <div className="mb-3 w-100 me-2">
                            <div className="mb-2">
                                <span><T>Titel</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={news.title}
                                onChange={(e) => setNews({ ...news, title: e.target.value })}
                            />
                        </div>
                        <div className="w-100 mb-4 ms-2">
                            <div className="mb-2">
                                <span><T>Permanent link</T></span>
                            </div>
                            <Input
                                disabled
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={news.urlSlug}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="mb-2">
                            <div><T>Indhold</T></div>
                        </div>
                        <RichTextEditor
                            fieldValue={news.content}
                            update={(content) => setNews({ ...news, content })}
                            height={200}
                        />
                    </div>
                    <div className="w-100 mb-4">
                        <div className="mb-2">
                            <span><T>Kort beskrivelse</T></span>
                        </div>
                        <Input
                            type="textarea"
                            className="py-3 text-weight-reg input-light-blue"
                            value={news.shortDescription}
                            onChange={(e) => setNews({ ...news, shortDescription: e.target.value })}
                        />
                    </div>
                    <div className="mb-4">
                        <div className="mb-2">
                            <span><T>Billede</T></span>
                        </div>
                        <DropLoad
                            files={files}
                            setFiles={setFiles}
                            setDeleted={() => deleteImage()}
                            uploadFiles={handleUploadImage}
                            updateOrder={null}
                            multiple={false}
                            imagesUrls={images}
                            canUpload={true}
                        />
                    </div>
                    <div className="my-5">
                        <Advanced news={news} setNews={setNews} type={"news"} MetaDesc={"Meta description here"} PageTitle={"Meta title here"} />
                    </div>
                    <div className="w-100 me-3 d-flex">
                        <div className="w-100">
                            <div className="mb-2 mt-1">
                                <span><T>Slet Arrangement</T></span>
                            </div>
                            <div>
                                <VerifyDelete
                                    deleteAction={() => deleteNews()}
                                    deleteText={<T>Delete News</T>}
                                    prompt={<T>Type 'delete news' to delete the news</T>}
                                    verifyText="delete news" />
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardContainer>
            <Kbutton custom="px-5 py-2 mt-3" onClick={() => saveNews()} prim md><T>Gem</T></Kbutton>
        </div>

    );
}

export const EditEventContent = ({ uploadImage, saveEvent, event, setEvent, deleteEvent }) => {

    const [files, setFiles] = useState([]);

    const deleteImage = () => {
        setFiles([])
        setEvent({ ...event, imageUrl: "" })
        saveEvent({ ...event, imageUrl: "" })
    };

    const handleUploadImage = (images) => {
        uploadImage(files)
        setFiles([])
    };

    const images = (event["imageUrl"] && event["imageUrl"] !== "") ? event["imageUrl"] : ""

    const updateStartDateTime = (date) => {
        const timestamp = date.getTime();
        setEvent({ ...event, timestamp: timestamp });
    };

    return (
        <div className="w-100">
            <DashboardContainer>
                <DashboardContainerHeader border><T>Tekst</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4">
                    <div className="d-flex">
                        <div className="mb-3 w-50 me-2">
                            <div className="mb-2">
                                <span><T>Titel</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={event.title}
                                onChange={(e) => setEvent({ ...event, title: e.target.value })}
                            />
                        </div>
                        <div className="w-50 mb-4 ms-2">
                            <div className="mb-2">
                                <span><T>Permanent link</T></span>
                            </div>
                            <Input
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={event.urlSlug}
                                onChange={(e) => setEvent({ ...event, urlSlug: e.target.value })}
                            />
                        </div>
                        <div className="w-50 mb-4 ms-2">
                            <div className="mb-2">
                                <span><T>Dato for arrangement</T></span>
                            </div>
                            <Input
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={event.dateTime}
                                onChange={(e) => setEvent({ ...event, dateTime: e.target.value })}
                            />
                        </div>
                        <div className="w-50 mb-4 ms-2">
                            <div className="mb-1">
                                <span>Calendar</span>
                            </div>
                            <div>
                                <DatePicker
                                    selected={event.timestamp ? event.timestamp : null}
                                    timeIntervals={15}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    onChange={updateStartDateTime}
                                    className="form-control text-weight-reg py-3"
                                    placeholderText={"Start date & time"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mb-4">
                        <div className="mb-2">
                            <span><T>Lokation</T></span>
                        </div>
                        <Input
                            type="text"
                            className="py-3 text-weight-reg input-light-blue"
                            value={event.location}
                            onChange={(e) => setEvent({ ...event, location: e.target.value })}
                        />
                    </div>
                    <div className="w-100 mb-4">
                        <div className="mb-2">
                            <span><T>Kort beskrivelse</T></span>
                        </div>
                        <Input
                            type="textarea"
                            className="py-3 text-weight-reg input-light-blue"
                            value={event.description}
                            onChange={(e) => setEvent({ ...event, description: e.target.value })}
                        />
                    </div>
                    <div className="mb-4">
                        <div className="mb-2">
                            <span><T>Billede</T></span>
                        </div>
                        <DropLoad
                            files={files}
                            setFiles={setFiles}
                            setDeleted={() => deleteImage()}
                            uploadFiles={handleUploadImage}
                            updateOrder={null}
                            multiple={false}
                            imagesUrls={images}
                            canUpload={true}
                        />
                    </div>
                    <div className="w-100 me-3 d-flex">
                        <div className="w-100">
                            <div className="mb-2 mt-1">
                                <span><T>Slet Arrangement</T></span>
                            </div>
                            <div>
                                <VerifyDelete
                                    deleteAction={() => deleteEvent()}
                                    deleteText={<T>Delete Event</T>}
                                    prompt={<T>Type 'delete event' to delete the event</T>}
                                    verifyText="delete event" />
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardContainer>
            <Kbutton onClick={() => saveEvent()} custom="px-5 py-2 mt-3" prim md><T>Gem</T></Kbutton>
        </div>

    );
}

export const EditMagazineContent = ({ uploadImage, setMag, mag, saveMagazine, deleteMagazine, ...props }) => {
    const [files, setFiles] = useState([])

    const deleteImage = () => {
        setFiles([])
        setMag({ ...mag, imageUrl: "" })
        saveMagazine({ ...mag, imageUrl: "" })
    }

    const handleUploadImage = (images) => {
        uploadImage(files)
        setFiles([])
    }

    const images = (mag["imageUrl"] && mag["imageUrl"] !== "") ? mag["imageUrl"] : ""
    return (
        <div className="w-100">
            <DashboardContainer>
                <DashboardContainerHeader border><T>Tekst</T></DashboardContainerHeader>
                <div className="px-4 pb-4 pt-4">
                    <div className="d-flex">
                        <div className="mb-3 w-100 me-2">
                            <div className="mb-2">
                                <span><T>Titel</T></span>
                            </div>
                            <Input
                                className="py-3 text-weight-reg input-light-blue"
                                value={mag.title}
                                onChange={(e) => setMag({ ...mag, title: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="w-100 mb-4">
                            <div className="mb-2">
                                <span><T>Link til magasin</T></span>
                            </div>
                            <Input
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={mag.link}
                                onChange={(e) => setMag({ ...mag, link: e.target.value })}
                            />
                        </div>
                        <div className="w-100 mb-4 ms-2">
                            <div className="mb-2">
                                <span><T>Dato for magasin</T></span>
                            </div>
                            <Input
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={mag.date}
                                onChange={(e) => setMag({ ...mag, date: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="mb-2">
                            <span><T>Billede</T></span>
                        </div>
                        <DropLoad
                            files={files}
                            setFiles={setFiles}
                            setDeleted={() => deleteImage()}
                            uploadFiles={handleUploadImage}
                            updateOrder={null}
                            multiple={false}
                            imagesUrls={images}
                            canUpload={true}
                        />
                    </div>
                    <div className="w-100 me-3 d-flex">
                        <div className="w-100">
                            <div className="mb-2 mt-1">
                                <span><T>Slet Magasin</T></span>
                            </div>
                            <div>
                                <VerifyDelete
                                    deleteAction={() => deleteMagazine()}
                                    deleteText={<T>Delete Magazine</T>}
                                    prompt={<T>Type 'delete magazine' to delete the magazine</T>}
                                    verifyText="delete magazine" />
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardContainer>
            <Kbutton custom="px-5 py-2 mt-3" prim md onClick={() => saveMagazine()}><T>Gem</T></Kbutton>
        </div>

    );
};

export const Advanced = ({ MetaDesc, PageTitle, type, news, setNews }) => {

    const { data, setData } = useContext(DataContext);

    const updateDesc = (text) => {
        if (type === "venue") {
            setData({ ...data, metaDesc: text })
        } else if (type === "news") {
            setNews({ ...news, metaDesc: text })
        }
    };

    const updateTitle = (text) => {
        if (type === "venue") {
            setData({ ...data, metaTitle: text })
        } else if (type === "news") {
            setNews({ ...news, metaTitle: text })
        }
    };

    return (
        <Accordion>
            <DashboardContainer>
                <div className="p-4 pb-0">
                    <Notification>
                        <T>This section is intended for advanced users and SEO optimization. Edit at own risk.</T>
                    </Notification>
                </div>
                <div style={{ fontFamily: "monospace" }}>
                    <div className="px-4 pb-4 pt-4">
                        <div>
                            <div className="mb-2">
                                <span>{"<title></title>"}</span>
                            </div>
                            <Input
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={type === "venue" ? data.metaTitle : news.metaTitle}
                                placeholder={PageTitle}
                                onChange={(e) => updateTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="px-4 pb-4">
                        <div>
                            <div className="mb-2">
                                <span>{`<meta name="description" content="">`}</span>
                            </div>
                            <Input
                                type="textarea"
                                className="py-3 text-weight-reg input-light-blue"
                                value={type === "venue" ? data.metaDesc : news.metaDesc}
                                placeholder={MetaDesc}
                                onChange={(e) => updateDesc(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </DashboardContainer>
        </Accordion>
    );
};

export const Premium = ({ supplier = undefined }) => {

    const { data, setData } = useContext(DataContext);

    const thumb = <img alt="thumb" width={20} height={20} src={"https://static-konference-dk.s3.eu-north-1.amazonaws.com/gfx/ico_admin_premium.svg"} />


    return (
        <DashboardContainer>
            <DashboardContainerHeader thumb={thumb} border><T>Premium</T></DashboardContainerHeader>
            <div>
                <Row className="px-4 pb-3 pt-4">
                    {supplier ? <div className="mb-2 w-50"><T>Promote this supplier</T></div> : <div className="mb-2 w-50"><T>Promote this venue</T></div>}
                    <span className='w-50'>{supplier ? <T>When set above 1 the supplier will be listed at the top</T> : <T>When set above 1 the venue will be listed at the top</T>}</span>
                </Row>
                <div className="pb-4 ms-4 w-25">
                    <LDropDown
                        data={premiumData}
                        placeholder={() => {
                            if (data.priority === 1) {
                                return ("Normal")
                            } else if (data.priority) {
                                return (data.priority)
                            }
                        }}
                        height={50}
                        setVal={(clickData) => {
                            setData({ ...data, priority: clickData });
                        }}
                    />
                    {!supplier &&
                        <div className="mt-4">
                            <div className="mb-2">
                                <span><T>Dollhouse 3D URL</T></span>
                            </div>
                            <Input
                                value={data.dollHouseUrl}
                                onChange={(e) => setData({ ...data, dollHouseUrl: e.target.value })}
                                className="py-3 text-weight-reg input-light-blue"
                            />
                        </div>
                    }
                    <div className="mt-4">
                        <div className="mb-2">
                            <span><T>Video</T>&nbsp;(embedded YouTube link)</span>
                        </div>
                        <Input
                            value={data.videoLink}
                            onChange={(e) => setData({ ...data, videoLink: e.target.value })}
                            className="py-3 text-weight-reg input-light-blue"
                        />
                    </div>
                </div>
            </div>
        </DashboardContainer>
    );
};

const premiumData = [
    {
        name: 'Normal',
        id: 1,
    },
    {
        name: '2',
        id: 2,
    },
    {
        name: '3',
        id: 3,
    },
    {
        name: '4 - Focused',
        id: 4,
    },
    {
        name: '5 - Focused',
        id: 5,
    },
];

export const EditBannersContent = ({ uploadImage, banner, setBanner, saveBanner, deleteItem }) => {

    const [files, setFiles] = useState([]);
    const [bannerImage, setBannerImage] = useState([]);
    const [savePrompt, setSavePrompt] = useState(false);

    const handleSave = () => {
        setSavePrompt(false);
        saveBanner();
    };

    const handleImageUpload = () => {
        uploadImage(files);
        setFiles([]);
    };

    const deleteImage = (type) => {
        setFiles([]);
        const newBanners = delete banner[type];
        setBanner(newBanners);
    };

    const infoText = <div>
        <div><T>Skyscraper banner skal minimum være 160px i bredden og 600px i højde</T></div><div className='mt-1'><T>Billboard banner skal minimum være 970px i bredden og 250px i højde</T></div>
    </div>;

    useEffect(() => {
        setBannerImage(banner["image_url"])
        // eslint-disable-next-line
    }, [banner])

    const toggleVisibility = () => {
        setBanner({ ...banner, visible: !banner.visible });
        setSavePrompt(true);
    };

    return (
        <div className="w-100">
            <div className='d-flex'>
                <span className='ms-1 me-3 my-1'><T>Banner synligt på hjemmesiden</T></span>
                <ToggleSwitch
                    isOn={banner.visible}
                    handleToggle={() => toggleVisibility()}
                />
            </div>
            {savePrompt && <div className='mb-3 red_text'><T>Klik på Gem for at gøre ændringer synlige</T></div>}
            <DashboardContainer>
                <DashboardContainerHeader border><T>Rediger Banner</T></DashboardContainerHeader>
                <div className="px-4 py-4">
                    <div className="d-flex">
                        <div className="w-100 mb-4 ms-2">
                            <div className="mb-2">
                                <span><T>Titel</T></span>
                            </div>
                            <Input
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={banner.title}
                                onChange={(e) => setBanner({ ...banner, title: e.target.value })}
                            />
                        </div>
                        <div className="w-100 mb-4 ms-2">
                            <div className="mb-2">
                                <span><T>URL</T></span>
                            </div>
                            <Input
                                type="text"
                                className="py-3 text-weight-reg input-light-blue"
                                value={banner.link}
                                onChange={(e) => setBanner({ ...banner, link: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='pb-4'>
                        <div className="mt-2 ms-2">Banner type: <span style={{textTransform: "capitalize"}} className='text-bold'>{banner.banner_type}</span></div>
                        <div className="d-flex ms-1 mt-2">
                            <LDropDown
                                placeholder="Valg banner type"
                                data={bannerData}
                                height={50}
                                setVal={(clickData) => setBanner({ ...banner, banner_type: clickData })}
                            />
                        </div>
                    </div>
                    {!banner.banner_type && <div className='mb-3 red_text'><T>Valg banner type før du kan tilføje billede</T></div>}
                    {banner.banner_type &&
                        <div className="mb-4">
                            <div className="mb-2">
                                <Notification>{infoText}</Notification>
                            </div>
                            <DropLoad
                                files={files}
                                setFiles={setFiles}
                                uploadFiles={handleImageUpload}
                                setDeleted={() => deleteImage("skyscraperImage")}
                                updateOrder={null}
                                multiple={false}
                                imagesUrls={bannerImage}
                                canUpload={true}
                                previewHeight={banner.banner_type === "skyscraper" ? 600 : 250 }
                                previewWidth={banner.banner_type === "skyscraper" ? 160 : 970 }
                                noDelete={true}
                            />
                        </div>
                    }
                </div>
            </DashboardContainer>
            <Kbutton custom="px-5 py-2 mt-3" onClick={() => handleSave()} prim md><T>Gem</T></Kbutton>
            <div className='mt-3'>
                <VerifyDelete
                    deleteAction={() => deleteItem()}
                    deleteText={<T>Delete banner</T>}
                    prompt={<T>Type 'delete banner' to delete the item</T>}
                    verifyText="delete banner"
                />
            </div>
        </div>
    );
};

const bannerData = [
    {
        name: 'Skyscraper',
        id: 'skyscraper',
    },
    {
        name: 'Billboard',
        id: 'billboard',
    },
];